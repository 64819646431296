<template>
  <div class="relative logo-container">
    <router-link :to="{ name: 'Welcome' }">
<!--      <img src="../assets/logo.png" class="logo">-->
      <Logo1 class="logo" />
    </router-link>
  </div>
</template>

<script>
import Logo1 from '@/components/logos/logo-1'

export default {
  name: 'Logo',
  components: {
    Logo1
  }
}
</script>

<style scoped>
  .logo{
    display: block;
    margin: 15px auto;
    width: auto;
    max-width: 320px;
  }
</style>
