<template>
    <div
      class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition-all overflow-hidden duration-500"
      :class="{ 'h-full': userSelect !== null, 'h-0': userSelect === null, }"
    >
      <header class="text-left font-bold border-b border-gray">
        <button @click="userSelect = false" class="flex select-none items-center justify-start space-x-2 py-4 px-4">
          <i class="icon-arrowLeft" />
          <div class="flex flex-col flex-grow items-start">
            <p class="uppercase cursor-pointer font-semibold">Regresar</p>
          </div>
        </button>
      </header>
      <div class="h-full flex flex-col items-center space-y-16">
        <div class="container mt-24">
          <!--        <img src="../../assets/logo.png" class="mx-auto">-->
          <p class="text-xl">Felicidades! Te han invitado a</p>
          <Logo class="mx-auto"></Logo>
          <p class="text-lg">Codigo de invitación: </p>
          <p class="font-bold">{{ invitation_code }}</p>
        </div>
        <div class="space-y-8">

          <p class="px-36">
            ¿Eres un foodie o un restaurante?
          </p>

          <div class="absolute bottom-0 flex flex-col items-center space-y-4 pb-6 inset-x-0 px-4 overflow-hidden">
            <button class="uppercase focus:outline-none py-3 w-full bg-primary text-white rounded-full" @click="userType = 1">
              Soy un foodie
            </button>
            <button class="uppercase focus:outline-none py-3 w-full bg-white border border-primary rounded-full text-primary" @click="userType = 2">
              Soy un restaurante
            </button>
          </div>
        </div>
      </div>
    </div>

    <a-alert :show="userType !== null" title="Antes de continuar" @confirm="redirect" @cancel="userType = null">
      <p>¿Seguro que deseas continuar como {{ userType === 1 ? 'foodie' : 'restaurante' }}?</p>
    </a-alert>

    <a-alert hide-cancel :show="loginError" title="Ha ocurrido un error" @confirm="loginError = false">
      <p>El Email o la contraseña es incorrecta</p>
    </a-alert>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import Logo from '@/components/Logo.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Invitation',
  components: {
    Logo
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  data () {
    return {
      invitation_code: '',
      loading: false,
      userType: null,
      userSelect: '',
      loginError: false,
      haveCode: false
    }
  },
  methods: {
    redirect () {
      this.$router.push({ name: 'register.native', params: { usertype: this.userType }, query: { invitation_code: this.invitation_code } })
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.replace({ name: 'Explore' })
    } else {
      if (this.$route.query.invitation_code) {
        this.invitation_code = this.$route.query.invitation_code
        this.haveCode = true
      } else {
        this.haveCode = false
        this.$router.replace({ name: 'Login' })
      }
    }
  }
}
</script>

<style scoped>

</style>
